import { FC, useState } from 'react';
import { SelectProps } from '@mantine/core';
import classes from './FilterSelect.module.scss';
import PebbleSelect from 'components/common/PebbleSelect/PebbleSelect';
import classNames from 'classnames';
import { CaretDown } from '@phosphor-icons/react';

export const FilterSelect: FC<
  Pick<SelectProps, 'placeholder' | 'data' | 'defaultValue' | 'value' | 'onChange' | 'onFocus'>
> = (props) => {
  const { placeholder, defaultValue, value, onChange, onFocus, data } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <PebbleSelect
      withCheckIcon={false}
      placeholder={placeholder}
      defaultValue={defaultValue}
      data={data}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onDropdownOpen={() => {
        setDropdownOpen(true);
      }}
      onDropdownClose={() => setDropdownOpen(false)}
      rightSection={
        <CaretDown
          size={18}
          weight="bold"
          className={classNames({
            [classes.iconOpen]: dropdownOpen,
            [classes.iconClose]: !dropdownOpen,
          })}
        />
      }
      classNames={{
        section: classes.rightSection,
        dropdown: classes.dropdown,
        option: classes.option,
        input: classNames(classes.input, { [classes.filterSelectedInput]: value !== defaultValue }),
      }}
    />
  );
};
