import { Button, Grid, Group, Skeleton, Table, Text, useMantineTheme } from '@mantine/core';
import classes from './ActivityFinancialReport.module.scss';
import { useLazyQuery } from '@apollo/client';
import { FinanceReportFiltersQuery } from 'graphql/queries';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useEffect, useMemo, useState } from 'react';
import { FilterSelect } from 'components/FilterSelect';
import { DateFilters, getEnumKeyByEnumValue } from 'utils/bookingsFilters/filters';
import dayjs from 'dayjs';
import { DateFilter } from 'components/DateFilter';
import queryString from 'query-string';
import { downloadFile } from 'utils/downloadFile/downloadFile';
import { getShortISOString } from 'utils/getShortIsoDate/getShortIsoDate';
import { LabelWithPopover } from 'components/LabelWithPopover/LabelWithPopover';
import { formatPrice } from 'utils/formatPrice';
import { Actions, trackAction } from 'utils/amplitude';
import { IntercomEvents, showNewIntercomMessage, trackIntercomEvent } from '@utils';
import { FinancialReportActivities } from 'types';
import { ITotalAndUpcomingPaymentsData } from 'interfaces';
import classNames from 'classnames';
import { CaretDown, FileArrowDown, CalendarBlank } from '@phosphor-icons/react';

interface IActivityFinancialReportProps {
  token: string;
  supplierId: string;
  supplierName: string;
  dateFilterActive: boolean;
  setDateFilterActive(val: boolean): void;
}
interface IActivitiesLabelAndValue {
  label: string;
  value: string | null;
}

type AllDateFilterType = {
  dateFilter: DateFilters | null;
  customDateFilter: [Date | null, Date | null];
};

const ActivityFinancialReport: React.FC<IActivityFinancialReportProps> = ({
  token,
  supplierId,
  supplierName,
  dateFilterActive,
  setDateFilterActive,
}: IActivityFinancialReportProps) => {
  const theme = useMantineTheme();

  const [showError, setShowError] = useState(false);

  const [getData, { loading, data }] = useLazyQuery<ITotalAndUpcomingPaymentsData>(
    FinanceReportFiltersQuery,
    {
      context: {
        headers: {
          Authorization: `${token}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (error) => {
        console.log(error);
        showErrorMessage(error);
      },
    },
  );

  const defaultDate: AllDateFilterType = {
    dateFilter: DateFilters['All dates'],
    customDateFilter: [null, null],
  };

  const [selectedActivityFilter, setSelectedActivityFilter] = useState<string>('all-activities');

  const [allDateFilter, setAllDateFilter] = useState<AllDateFilterType>(defaultDate);

  const { dateFilter, customDateFilter } = allDateFilter;

  const activityFilters = useMemo(() => {
    const activitiesFromAPI = data?.financeReportFiltersAndTotals?.activities || [];

    const getLabelAndValueActivities = activitiesFromAPI.map(
      (activity: FinancialReportActivities) => ({
        label: activity.name,
        value: activity.id,
      }),
    );

    const activityLabels: IActivitiesLabelAndValue[] = [
      { label: 'All activities', value: 'all-activities' },
      ...getLabelAndValueActivities,
    ];

    return activityLabels;
  }, [data?.financeReportFiltersAndTotals?.activities]);

  const dateFilterLabel = useMemo(() => {
    const [min, max] = customDateFilter;

    if (min && max) {
      const dayJsMin = dayjs(min);
      const dayJsMax = dayjs(max);
      const isSame = dayJsMin.isSame(dayJsMax, 'day');
      if (isSame) {
        return dayjs(min).format('DD MMM YYYY');
      }
      const isSameYear = dayJsMin.isSame(dayJsMax, 'year');
      const minFormat = isSameYear ? 'DD MMM' : 'DD MMM YYYY';
      return `${dayjs(min).format(minFormat)} - ${dayjs(max).format('DD MMM YYYY')}`;
    }
    if (min) {
      return dayjs(min).format('DD MMM YYYY');
    }
    if (dateFilter) {
      return getEnumKeyByEnumValue(DateFilters, dateFilter);
    }
    return '';
  }, [customDateFilter, dateFilter]);

  const getStartAndEndDateFilters = (
    dateSelectedFilter: DateFilters | null,
    customDateSelectedFilter: [Date | null, Date | null],
  ): Partial<{ datetimeStart: string | null; datetimeEnd: string | null }> => {
    const todayUTC = dayjs(new Date()).utc();
    const [min, max] = customDateSelectedFilter;

    if (min && max) {
      return {
        datetimeStart: dayjs.utc(min).toISOString(),
        datetimeEnd: dayjs.utc(max).add(1, 'day').toISOString(),
      };
    }

    if (min) {
      return {
        datetimeStart: dayjs.utc(min).toISOString(),
        datetimeEnd: dayjs.utc(min).add(1, 'day').toISOString(),
      };
    }

    switch (dateSelectedFilter) {
      case DateFilters.Past:
        return {
          datetimeEnd: todayUTC.startOf('day').toISOString(),
        };
      case DateFilters.Upcoming:
        return {
          datetimeStart: todayUTC.startOf('day').toISOString(),
        };

      case DateFilters['All dates']:
      default:
        return {};
    }
  };

  useEffect(() => {
    if (supplierId) {
      getData({
        variables: {
          supplierId,
          ...getStartAndEndDateFilters(allDateFilter.dateFilter, allDateFilter.customDateFilter),
        },
      });
    }
  }, [supplierId, allDateFilter, getData]);

  const requestReportUrl = async () => {
    const { datetimeStart, datetimeEnd } = getStartAndEndDateFilters(dateFilter, customDateFilter);

    const params = {
      ...(datetimeStart && {
        datetime_start: getShortISOString(datetimeStart),
      }),
      ...(datetimeEnd && {
        datetime_end: getShortISOString(datetimeEnd),
      }),
      ...(selectedActivityFilter !== 'all-activities' && {
        activity_id: selectedActivityFilter,
      }),
    };

    const paramsString = queryString.stringify(params);

    let endpointUrl = `${process.env.NEXT_PUBLIC_REPORTS_ENDPOINT}/activity_transactions/${supplierId}`;

    if (datetimeStart || datetimeEnd || selectedActivityFilter !== 'all-activities') {
      endpointUrl = `${process.env.NEXT_PUBLIC_REPORTS_ENDPOINT}/activity_transactions/${supplierId}?${paramsString}`;
    }

    const formatSupplierName = supplierName.replace(/\s/g, '');

    const fileName = `Activity_Transactions_${formatSupplierName}.csv`;

    try {
      const response = await fetch(endpointUrl, {
        headers: {
          Authorization: token,
        },
      });
      const { reportUrl } = await response.json();
      await downloadFile(reportUrl, fileName);
    } catch (e) {
      showErrorMessage(e);
    }
  };

  return (
    <>
      <Grid mt="md" mb="sm" grow>
        <Grid.Col span={{ base: 4, md: 6 }}>
          <Text
            mb="sm"
            size="md"
            style={{
              fontWeight: 600,
            }}
          >
            Total payments last 30 days:
          </Text>
          {data && (
            <LabelWithPopover
              size="sm"
              labelText={
                <Text
                  mt={2}
                  style={{
                    fontWeight: 800,
                    fontSize: 20,
                  }}
                >
                  {formatPrice(data?.financeReportFiltersAndTotals.totalSucceededLast30Days)}
                </Text>
              }
              popoverContent={
                <>
                  <Text>Total completed transfers (fees have been deducted).</Text>
                  <br />
                  <Text>Cancelled bookings are excluded.</Text>
                </>
              }
            />
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 4, md: 6 }}>
          <Text
            mb="sm"
            size="md"
            style={{
              fontWeight: 600,
            }}
          >
            Total upcoming payments:
          </Text>
          {data && (
            <LabelWithPopover
              size="sm"
              labelText={
                <Text
                  mt={2}
                  style={{
                    fontWeight: 800,
                    fontSize: 20,
                  }}
                >
                  {formatPrice(data.financeReportFiltersAndTotals.totalPending)}
                </Text>
              }
              popoverContent={
                <>
                  <Text>
                    Total upcoming payments (after booking fee) due to arrive in your Stripe
                    account.
                  </Text>
                </>
              }
            />
          )}
        </Grid.Col>
      </Grid>
      <Text
        mt="sm"
        size="lg"
        style={{
          fontWeight: 600,
        }}
      >
        Use the filters below to export a breakdown of payments:
      </Text>
      <Text
        mt="sm"
        style={{
          fontSize: 14,
        }}
      >
        Here you can view a breakdown of payments made by customers and transferred to your stripe
        account, as well as pending payments. Select a date or date range to export bookings made
        within that period.
      </Text>
      <Group pt={theme.spacing.lg} className={classes.toolbar}>
        {loading ? (
          <Table>
            <tbody>
              <tr>
                <td>Loading...</td>
                <td className="tdNoResult" colSpan={4}>
                  <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <>
            <Button
              onClick={() => {
                setShowError(false);
                setDateFilterActive(!dateFilterActive);
              }}
              leftSection={<CalendarBlank size={24} color={theme.colors.gray[6]} />}
              rightSection={<CaretDown size={18} weight="bold" color={theme.colors.gray[6]} />}
              classNames={{
                // root: classes.filterButton,
                root: classNames(classes.filterButton, {
                  [classes.filterSelected]: dateFilterLabel !== 'All dates',
                }),
                label: classes.filterButtonLabel,
                section: classNames({
                  [classes.buttonIconRotate]: dateFilterActive,
                }),
              }}
            >
              {dateFilterLabel}
            </Button>
            <DateFilter
              isHomePage={true}
              open={dateFilterActive}
              onClose={() => setDateFilterActive(false)}
              activeFilter={customDateFilter[0] ? null : dateFilter}
              activeCustomDateFilter={customDateFilter}
              onDateFilterChange={(filter) => {
                setAllDateFilter({
                  customDateFilter: [null, null],
                  dateFilter: filter,
                });
              }}
              onCustomDateChange={(dates) => {
                setAllDateFilter({
                  customDateFilter: dates,
                  dateFilter: dates[0] ? null : DateFilters['All dates'],
                });
              }}
            />
            <FilterSelect
              defaultValue={'all-activities'}
              value={selectedActivityFilter}
              onChange={(value: string | null) => {
                if (!value) return;
                trackAction(Actions.DASH_ACTIVITY_PICKER);
                setSelectedActivityFilter(value);
              }}
              onFocus={() => setShowError(false)}
              // eslint-disable-next-line
              // @ts-ignore
              data={activityFilters}
            />

            <Button
              className={classes.exportButton}
              size="sm"
              radius="lg"
              variant="outline"
              onClick={async () => {
                if (data?.financeReportFiltersAndTotals?.activities.length === 0) {
                  setShowError(true);
                } else {
                  await requestReportUrl();
                }
                trackAction(Actions.DASH_EXPORT_FINANCE);
                trackIntercomEvent(IntercomEvents.EXPORT_FINANCES);
              }}
              leftSection={<FileArrowDown size={26} />}
            >
              Export
            </Button>
          </>
        )}
      </Group>
      {showError && (
        <Text c="red" size="sm">
          There are no bookings for these date(s)
        </Text>
      )}
      <Text
        mb="sm"
        size="sm"
        style={{
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Can't find the data you want? Contact our customer support team{' '}
        <Button
          className={classes.hereLink}
          onClick={() => {
            showNewIntercomMessage();
          }}
        >
          here.
        </Button>
      </Text>
    </>
  );
};

export default ActivityFinancialReport;
